import { render, staticRenderFns } from "./404.vue?vue&type=template&id=096c4363&scoped=true&"
import script from "./404.vue?vue&type=script&lang=js&"
export * from "./404.vue?vue&type=script&lang=js&"
import style0 from "./404.vue?vue&type=style&index=0&id=096c4363&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_cutmzj7votxcrccm4agyl3addi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "096c4363",
  null
  
)

export default component.exports